import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'animate.css/animate.min.css'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-awesome/icons'
import 'vue-awesome/icons/spinner'
import Icon from 'vue-awesome/components/Icon'
import Particles from "particles.vue"; 

Vue.use(Particles);

Vue.config.productionTip = false

Vue.use(VueAxios, axios)   
Vue.use(BootstrapVue) 
Vue.component('icon', Icon) 
Vue.component('v-icon', Icon)


axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'; 
axios.defaults.headers.common['blox-authz'] = localStorage.getItem("tokenId");
axios.defaults.headers.common['Cookie'] = "JSESSIONID=" + localStorage.getItem("tokenId");
axios.defaults.headers.common['Set-Cookie'] = "JSESSIONID=" + localStorage.getItem("tokenId");
  
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
  }

  return value
})
